import React, { useEffect, useState } from "react";
import { Modal, Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDropzone } from "react-dropzone";
import { api } from "src/services/api";
import { useUser } from "src/contexts/user-context";
import { PutObjectCommand, ListObjectsV2Command } from "@aws-sdk/client-s3";
import toast from "react-hot-toast";
import StageOne from "./components/stage-one";
import StageTwo from "./components/stage-two";
import StageThree from "./components/stage-three";
import { s3 } from "src/utils/storage-provider";

const MAX_FILES = 10;

const ReviewModal = ({ open, onClose, selectedReturnId, availableActions, updateAttachmentStatus, updateReturnData }) => {
  const [loading, setLoading] = useState(false);
  const [failReasons, setFailReasons] = useState([]);
  const [stage, setStage] = useState(1);
  const [selectedReason, setSelectedReason] = useState(null);
  const [s3Files, setS3Files] = useState([]);
  const [description, setDescription] = useState("");
  const { user } = useUser();

  const fetchReturnFailReason = async () => {
    try {
      setLoading(true);
      const response = await api.get(
        `/v1/claims/return-fail-reason/${selectedReturnId}`
      );
      setFailReasons(response.data);
    } catch (error) {
      console.error("Erro ao buscar razões ", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSendReviewFail = async () => {
    try {
      setLoading(true);
      await api.post(`/v1/claims/send-return-review-fail/${selectedReturnId}`, {
        reason: selectedReason.id,
        message: description,
        s3Keys: s3Files.map((file) => file.key),
      });
      toast.success("Revisão enviada com sucesso");      
      handleClose();
      } catch (error) {
      toast.error("Erro ao enviar revisão");
    } finally {
      updateReturnData(true);
      setLoading(false);
    }
  };

  const fetchS3Files = async () => {
    const attachmentsPath = `tenants/${user.customer}/returnAttachments/${selectedReturnId}/`;

    try {
      setLoading(true);
      const params = {
        Bucket: process.env.REACT_APP_AWS_BUCKET,
        Prefix: attachmentsPath,
      };
      const data = await s3.send(new ListObjectsV2Command(params));
      const s3Attachments = data.Contents
        ? data.Contents.map((item) => ({
            name: item.Key.split("/").pop(),
            url: `https://assets-s3.weesutech.com.br/${item.Key}`,
            key: item.Key,
            type: item.Key.split(".").pop(), // Get file type
          })).filter((file) =>
            ["jpg", "jpeg", "png", "pdf"].includes(file.type)
          ) 
        : [];

      setS3Files(s3Attachments);
    } catch (error) {
      console.error("Erro ao carregar anexos do S3:", error);
      toast.error("Erro ao carregar anexos", {
        position: "bottom-right",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveS3FileFromList = (fileKey) => {
    setS3Files((prevFiles) => prevFiles.filter((file) => file.key !== fileKey));
  };

  const handleClose = () => {
    setFailReasons([]);
    setStage(1);
    setSelectedReason(null);
    setS3Files([]);
    setDescription("");
    onClose(false);
  };

  const handleReasonSelect = (reason) => {
    setSelectedReason(reason);
    setStage(3);
  };

  const handleBack = () => {
    if (stage === 3) {
      setSelectedReason(null);
      setStage(2);
    } else if (stage === 2) {
      setStage(1);
    }
  };

  const handleReviewOk = async () => {
    try {
      setLoading(true);
      await api.post(`/v1/claims/send-return-review-ok/${selectedReturnId}`);
      toast.success("Revisão enviada com sucesso");            
      handleClose();
    } catch (error) {
      toast.error("Erro ao enviar revisão");
    } finally {
      updateReturnData(true);
      setLoading(false);      
    }
  };

  useEffect(() => {
    if (open && stage === 2) {
      fetchReturnFailReason();
    }
    if (open && stage === 3) {
      fetchS3Files();
    }
  }, [open, stage]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          "&:focus-visible": {
            outline: "none",
          },
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          p: 4,
          bgcolor: "background.paper",
          borderRadius: "16px",
          maxWidth: 700,
          width: "90%",
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        {stage === 1 && (
          <StageOne
            loading={loading}
            handleReviewOk={handleReviewOk}
            setStage={setStage}
          />
        )}

        {stage === 2 && (
          <StageTwo
            loading={loading}
            failReasons={failReasons}
            handleReasonSelect={handleReasonSelect}
            handleBack={handleBack}
          />
        )}

        {stage === 3 && (
          <StageThree
            loading={loading}
            description={description}
            setDescription={setDescription}
            s3Files={s3Files}
            setS3Files={setS3Files}
            handleRemoveS3FileFromList={handleRemoveS3FileFromList}
            handleBack={handleBack}
            handleSendReviewFail={handleSendReviewFail}
            selectedReason={selectedReason}
            user={user}
            selectedReturnId={selectedReturnId}
            setLoading={setLoading}
            updateAttachmentStatus={updateAttachmentStatus}
          />
        )}
      </Box>
    </Modal>
  );
};

export default ReviewModal;
