import {
  createContext,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from "react";
import PropTypes from "prop-types";
import authApi from "../api/auth";
import userApi from "../api/user";
import { useNavigate } from "react-router";
import { modules } from "src/modules";
import _ from "lodash";
import useInterval from "../hooks/use-interval";
import jwt_decode from "jwt-decode";
import { toast } from "react-hot-toast";
import { useUser } from "./user-context";
import { setItem } from "src/utils/localForage";
import { api } from "src/services/api";
import { useNotificationsMessages } from "./notification-message-context";

const STORAGE_KEY = "accessToken";

var ActionType;
(function (ActionType) {
  ActionType["INITIALIZE"] = "INITIALIZE";
  ActionType["SIGN_IN"] = "SIGN_IN";
  ActionType["SIGN_UP"] = "SIGN_UP";
  ActionType["SIGN_OUT"] = "SIGN_OUT";
  ActionType["PERMISSIONS"] = "PERMISSIONS";
  ActionType["PARAMETERS"] = "PARAMETERS";
  ActionType["UPT_USER"] = "UPT_USER";
})(ActionType || (ActionType = {}));

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  permissions: null,
  parameters: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user, permissions, parameters } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      permissions,
      parameters,
    };
  },
  SIGN_IN: (state, action) => {
    const { user, permissions, parameters } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user,
      permissions,
      parameters,
    };
  },
  SIGN_UP: (state, action) => {
    const { user, permissions, parameters } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      permissions,
      parameters,
    };
  },
  SIGN_OUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
    permissions: null,
    parameters: null,
  }),
  UPT_USER: (state, action) => ({
    ...state,
    user: action.payload,
  }),
  PERMISSIONS: (state, action) => {
    return {
      ...state,
      permissions: action.payload,
    };
  },
  PARAMETERS: (state, action) => {
    return {
      ...state,
      parameters: action.payload,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

export const AuthContext = createContext({
  ...initialState,
  signIn: () => Promise.resolve(),
  signUp: () => Promise.resolve(),
  signOut: () => Promise.resolve(),
  uptUser: () => Promise.resolve(),
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const userContext = useUser();
  const {setNotificationsMessages} = useNotificationsMessages();
  const initialize = useCallback(async () => {
    try {
      const accessToken = window.localStorage.getItem(STORAGE_KEY);
      if (accessToken) {
       
        const { user, token } = await authApi.getAuthenticated(accessToken);

        if (!user) {
          window.localStorage.removeItem(STORAGE_KEY);
          dispatch({
            type: ActionType.INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
              permissions: null,
              parameters: null,
            },
          });
          navigate("/", { replace: true });
        } else { 
          const response = await api.get(`/v1/users/cache/${user.id}`);
          if(response.data){
            const cachedValues = JSON.parse(response.data);
            if(cachedValues && cachedValues.user && cachedValues.user.communicates)
              setNotificationsMessages(JSON.stringify(cachedValues.user.communicates));          
          }
          userContext.setUser(user);
          dispatch({
            type: ActionType.INITIALIZE,
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        }
      } else {
        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
            permissions: null,
            parameters: null,
          },
        });
      }
    } catch (err) {
      dispatch({
        type: ActionType.INITIALIZE,
        payload: {
          isAuthenticated: false,
          user: null,
          permissions: null,
          parameters: null,
        },
      });
    }
  }, [dispatch]);

  const refreshToken = async () => {
    const accessToken = window.localStorage.getItem(STORAGE_KEY);
    if (accessToken) {
      const { token } = await authApi.getAuthenticated(accessToken);
      if (token) {
        window.localStorage.setItem(STORAGE_KEY, token);
      }
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  const signIn = useCallback(
    async (email, password) => {
      try {
        const response = await authApi.signIn(email, password);
        if (response.user && response.token) {
          const { user, token } = response;
          window.localStorage.setItem(STORAGE_KEY, token);
          window.localStorage.setItem("chatKey", "");
          window.localStorage.setItem("tenant_id", response.user.tenant_id);
          window.localStorage.setItem("user_name", response.user.name);
          window.localStorage.setItem("avatar", response.user.avatar);
          window.localStorage.setItem("send_parameter", response.user.send_parameter);
          window.localStorage.removeItem("cpid");
          setItem('send_parameter', response.user.send_parameter);
          
          if (localStorage.getItem("isFreshWorksWidgetVisible") === null) {
            window.localStorage.setItem("isFreshWorksWidgetVisible", "true");
          }

          dispatch({
            type: ActionType.SIGN_IN,
            payload: {
              user,
            },
          });

          return { success: true, status: response.status, communicates: response.communicates };
        }

        return {
          success: false,
          status: 400,
          errorMessage: "Não foi possível realizar o login",
        };
      } catch (error) {
        console.log(error);
        return { status: error.response.status, ...error.response.data };
      }
    },
    [dispatch]
  );
  // const signUp = useCallback(
  //   async (payload) => {
  //     await authApi.signUp({
  //       ...payload,
  //     });

  //     dispatch({
  //       type: ActionType.SIGN_UP,
  //       payload: {},
  //     });
  //   },
  //   [dispatch]
  // );

  const signUp = async (payload) => {
    const response = await authApi.signUp({
      ...payload,
    });
    return response;
  };

  const signOut = useCallback(async () => {
    window.localStorage.removeItem("cpid");
    window.localStorage.removeItem("chatKey");
    window.localStorage.removeItem(STORAGE_KEY);
    dispatch({ type: ActionType.SIGN_OUT });
    navigate("/", { replace: true });
  }, [dispatch]);

  const uptUser = async () => {
    try {
      const data = await userApi.updateOne({ ...state.user });

      dispatch({
        type: ActionType.UPT_USER,
        payload: data,
      });

      if (!data.token) throw new Error("Não foi possivel recuperar o token");

      window.localStorage.setItem(STORAGE_KEY, data.token);
      toast.success("Informações pessoais atualizadas com sucesso!");
    } catch (error) {
      console.error(error);
    }
  };

  const setUser = (user) => {
    dispatch({
      type: ActionType.UPT_USER,
      payload: { ...state.user, ...user },
    });
  };

  useEffect(() => {
    if (state.permissions?.id) {
      const moduleId =
        Object.values(modules).find(
          (module) => module.path === window.location.pathname
        ) || {};
      if (moduleId?.id) {
        userApi
          .getParameters(moduleId.params)
          .then((data) => {
            dispatch({
              type: ActionType.PARAMETERS,
              payload: data,
            });
          })
          .catch((e) => {
            dispatch({
              type: ActionType.PARAMETERS,
              payload: null,
            });
          });
      }
    }
  }, [state.permissions]);

  useEffect(() => {
    const moduleId =
      Object.values(modules).find((module) => {
        if (module.regex) {
          const newRegExp = new RegExp(module.path);
          return newRegExp.test(window.location.pathname);
        } else {
          return module.path === window.location.pathname;
        }
      }) || {};

    if (moduleId?.id) {
      userApi
        .getPermission(moduleId.id)
        .then((data) => {
          dispatch({
            type: ActionType.PERMISSIONS,
            payload: data,
          });
        })
        .catch((err) => {
          dispatch({
            type: ActionType.PERMISSIONS,
            payload: null,
          });
          navigate("/", { replace: true }); // redirect to login
        });
    } else {
      dispatch({
        type: ActionType.PERMISSIONS,
        payload: null,
      });
    }
  }, [window.location.pathname]);

  /* useInterval(
    () => {
      const token = window.localStorage.getItem(STORAGE_KEY);
      if (token) {
        let decoded = jwt_decode(token);
        if (decoded.exp < Date.now() / 1000) {
          signOut();
        } else {
          refreshToken();
        }
      }
    },
    30000,
    false
  ); */

  return (
    <AuthContext.Provider
      value={{
        ...state,
        signIn,
        signUp,
        signOut,
        uptUser,
        setUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const AuthConsumer = AuthContext.Consumer;
