import PropTypes from "prop-types";
import { useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import { MobileNav } from "../mobile-nav";
import { TopNav } from "./top-nav";
import { useMobileNav } from "./use-mobile-nav";
import { createContext, useLayoutEffect, useRef, useState } from "react";
import { FreshworksWidgetProvider } from "src/contexts/fresh-works-widget-context";
import { ChatKeyProvider } from "src/contexts/chat-key-context";
import { FiltersProvider } from "src/contexts/filters-context";
import RedirectNotificationsMiddleware from "src/middleware/notifications-middleware";
import { TopNotificationAlert } from "./top-notification";
import { useNotificationsMessages } from "src/contexts/notification-message-context";

const HorizontalLayoutRoot = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
});

const HorizontalLayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
  width: "100%",
});

export const LayoutContext = createContext({});

export const HorizontalLayout = (props) => {
  const { children, navColor, sections } = props;
  const desktopUp = useMediaQuery((theme) => theme.breakpoints.up("desktop"));
  const mobileNav = useMobileNav();
  const [topNavHeight, setTopNavHeight] = useState(0);
  const [sectionHeight, setSectionHeight] = useState(0);
  const topNavRef = useRef(null);
  const sectionRef = useRef(null);
  const {notificationsMessages} = useNotificationsMessages();
  const notificationsMessagesObject = notificationsMessages && notificationsMessages.length > 0 ? JSON.parse(notificationsMessages) : null;

  useLayoutEffect(() => {
    if (topNavRef.current) {
      setTopNavHeight(topNavRef.current.clientHeight);
    }
    if (sectionRef.current) {
      setSectionHeight(sectionRef.current.clientHeight);
    }
  }, [topNavRef, sectionRef]);

  useLayoutEffect(() => {
    const handleResize = () => {
      if (topNavRef.current) {
        setTopNavHeight(topNavRef.current.clientHeight);
      }
      if (sectionRef.current) {
        setSectionHeight(sectionRef.current.clientHeight);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <LayoutContext.Provider value={{ topNavRef, sectionRef, topNavHeight, sectionHeight }}>
      <FreshworksWidgetProvider>
      <FiltersProvider>
      <ChatKeyProvider>
      <RedirectNotificationsMiddleware>
        {notificationsMessagesObject?.map((notificationMessage) => (
          <TopNotificationAlert notificationMessage={notificationMessage} />
        ))}
        <TopNav
          color={navColor}
          onMobileNav={mobileNav.handleOpen}
          sections={sections}
          ref={topNavRef}
        />      
        {!desktopUp && (
          <MobileNav
            color={navColor}
            onClose={mobileNav.handleClose}
            open={mobileNav.open}
            sections={sections}
          />
        )}
        <HorizontalLayoutRoot>
            <HorizontalLayoutContainer>{children}</HorizontalLayoutContainer>       
        </HorizontalLayoutRoot>
        </RedirectNotificationsMiddleware> 
        </ChatKeyProvider>
        </FiltersProvider>
      </FreshworksWidgetProvider>
    </LayoutContext.Provider>
  );
};

HorizontalLayout.propTypes = {
  children: PropTypes.node,
  navColor: PropTypes.oneOf(["blend-in", "discreet", "evident"]),
  sections: PropTypes.array,
};
